<template>
    <div class="index">
        <Header></Header>
        <div class="index-content">
            <div class="index-one">
                <el-carousel height="200px" ndicator-position="none" :autoplay="false">
                   <el-carousel-item v-for="item in itemObj.bannerList" :key="item.id" >
                     <img  :src="BaseUrl+item.image" alt="" class="swiper-img" @click='goDetails(item.id)'>
                     <span class="swiper-tit">{{item.title}}</span>
                    </el-carousel-item>
                </el-carousel>
            </div>
            <div class="one-item">
                <div class="item-list" v-for="item in itemObj.hotList" :key="item.id"
                 @click="goDetails(item.id)">
                    <div>
                        <span>{{item.title}}</span>
                        <div>
                            <span>时间：{{item.createtime}}</span>
                            <span>栏目：{{item.lname}}</span>
                        </div>
                    </div>
                    <img :src="BaseUrl + item.image" alt="">
                </div>
            </div>
            <div class="index-two">
                  <img :src="BaseUrl + itemObj.webupList[0].image" alt=""  @click='geturl(itemObj.webupList[0].linkurl)'>
            </div>
            <div class="index-three">     
                <div class="three-item">
                    <div class="item-list" v-for="item in itemObj.yingshiList" :key="item.id">
                        <span>{{item.title}}</span>
                        <video :src="BaseUrl+item.attrfile" controls></video>
                    </div>
                </div>   
            </div>
             <div class="three-image">
                    <img :src="BaseUrl + itemObj.webmiddleList[0].image" alt="" @click="geturl(itemObj.webupList[0].linkurl)"> 
            </div>
             <div class="index-four">
                <div class="four-item">
                    <div class="item-list" v-for="item in itemObj.renwuList" :key="item.id" @click="goHotDetails(item.id)">
                        <span >{{item.title}}</span>
                        <div>
                            <span>{{item.createtime}}</span>
                            <span>栏目：{{item.lname}}</span>
                        </div>
                    </div>
                     <div class="item-image" >
                        <img :src="BaseUrl + item.image" alt="" v-for="item in itemObj.webdownList" :key="item.id"  @click='geturl( item.linkurl)'> 
                     </div>
                    <div class="item-list" v-for="item in itemObj.newsList.slice(0,5)" :key="item.id"  @click="goDyDetails(item.id)">
                       <span>{{item.title}}</span>
                        <div>
                            <span>{{item.createtime}}</span>
                            <span>栏目：{{item.lname}}</span>
                        </div>
                    </div>
                </div>
            </div>

        </div>
        
        <Footer></Footer>
         <el-backtop :visibility-height="visibility" :right="30"></el-backtop>
    </div>
</template>

<script>
import Header from '../../../components/mobile/Header.vue'
import Footer from '../../../components/mobile/Footer.vue'
export default {
    name:'Index',
    data(){
        return{
           visibility:100,
           itemObj:''
        }
    },
    created(){
        this.getData()
    },
    methods:{
         getData(){
            this.$http({
                url:'/index/index'
            }).then(res=>{
                console.log(res.data,'index')
                this.itemObj = res.data
            })
        },
        geturl(url){
            console.log(url,"跳转路径");
           window.location.href = 'http://'+url;

        },
        goDetails(id){
            this.$router.push(`/m_indexdetail/${id}`)
        },
        goHotDetails(id){
            this.$router.push(`/m_indexfiguredetail/${id}`)
        },
        goDyDetails(id){
            this.$router.push(`/m_indexdydetail/${id}`)
        }
    },
    components:{
      Header,
      Footer
   }
}
</script>

<style lang='less' scoped>
/deep/.el-carousel__container{
    height: 362.26px!important;
}
/deep/.el-carousel__indicator--horizontal{
    display: none!important;
}
/deep/.el-carousel__arrow--right,
/deep/.el-carousel__arrow--left{
    display: none;
}
/deep/.el-backtop{
    margin-right: -20px!important;
}
.index{
    width: 750px;
    .index-content{
        width: 750px;
        margin-bottom: 70px;
        .index-one{
            position: relative;
            width: 750px;
            height: 362.26px;
            margin-bottom: 31px;
            .swiper-img{
                position: absolute;
                width: 750px;
                height: 362.26px;
            }
            .swiper-tit{
                position: absolute;
                display: block;
                width: 750px;
                height: 40px;
                line-height: 40px;
                bottom: 0;
                font-size: 24px;
                text-indent: 1.2em;
                color: #FFF;
                background-color: rgba(0, 0, 0, 0.3);
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
        }
        .one-item{
           .item-list{
               display: flex;
               align-items: center;
               width: 690px;
               height: 115px;
               margin: 0 auto 19px;
               padding-bottom: 20px;
               border-bottom: 1px solid #E5E5E5;
               >div:nth-child(1){

                  
                   margin-right: 25px;
                   >span:nth-child(1){
                       display: block;
                       width: 479px;
                       font-size: 28px;
                       color: #333333;
                       text-overflow: -o-ellipsis-lastline;
                       overflow: hidden;
                       text-overflow: ellipsis;
                       display: -webkit-box;
                       -webkit-line-clamp: 2;
                       line-clamp: 2;
                       -webkit-box-orient: vertical;
                   }
                   >div:nth-child(2){
                       margin-top: 10px;
                       font-size: 20px;
                       color: #666666;
                       >span:nth-child(1){
                           margin-right: 20px;
                       }
                   }

               }
               >img:nth-child(2){
                   width: 186px;
                   height: 110px;
               }
           }
        }
        .index-two{
            width: 750px;
            height: 100px;
            margin: 47px 0 50px 0;
            >img{
                width: 750px;
                height: 100px;
            }
        }
        .index-three{
            width: 690px;
            margin: 0 auto;
            .three-item{
                width: 690px;
                .item-list{
                    width: 690px;
                    margin-bottom: 28px;
                    padding-bottom: 30px;
                    border-bottom: 1px solid #E5E5E5;
                    >span:nth-child(1){
                        display: block;
                        font-size: 28px;
                        color: #333333;
                        margin-bottom: 23px;
                   }
                   >video:nth-child(2){
                       width: 690px;
                       height: 317px;
                   }
                }
            }       
        }
        .three-image{
            width: 750px;
            height: 100px;
            margin-top: 42px;
            margin-bottom: 39px;
            >img:nth-child(1){
                width: 750px;
                height: 100px;
            }
        }
        .index-four{
            width: 690px;
            margin: 0 auto;
            .four-item{
                width: 690px;
                .item-list{
                    margin-bottom: 19px; 
                    border-bottom: 1px solid #E5E5E5;
                    >span:nth-child(1){
                        font-size: 28px;
                        color: #333333;
                        text-overflow: -o-ellipsis-lastline;
                       overflow: hidden;
                       text-overflow: ellipsis;
                       display: -webkit-box;
                       -webkit-line-clamp: 2;
                       line-clamp: 2;
                       -webkit-box-orient: vertical;
                    }
                    >div:nth-child(2){
                        margin: 15px 0 20px 0;
                        font-size: 20px;
                        color: #666666;
                        >span{
                            margin-right: 33px;
                        }
                    }
                }
                 .item-image{
                        display: flex;
                        justify-content: space-between;
                        padding-bottom: 20px;
                        >img{
                            width: 220px;
                            height: 150px;
                        }
                    }
            }
        }
    }

}
</style>